import "./index.css"
import img1 from "../../../assets/images/heading.png"
import img2 from "../../../assets/images/4.png"
import img3 from "../../../assets/images/20.png"
import img4 from "../../../assets/images/19.png"
import img5 from "../../../assets/images/18.png"
import img6 from "../../../assets/images/17.png"
import img7 from "../../../assets/images/16.png"

function Products() {
    return (
        <div className="theme_container">
            <div className="product_container">
                <div className="product_image">
                    <img src={img2} alt="" />
                </div>
                <div className="product_content">
                    <div className="theme_heading">
                        <img src={img1} alt="" />
                        <h1>Looks Yummy Product Suite</h1>
                    </div>
                    <div className="../">
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Mobile App</h2>
                                <p>The LooksYummy App is the primary tool of the $LYT ecosystem and allows foodies to earn $LYT by performing positive platform actions such as sharing photos of their favorite meals, liking photos, commenting on posts, referring new users, and more. Our innovative e-comm social network enables new b2b and b2c markets to form around 2-way customer relationships.</p>
                            </div>
                            <div>
                                <img src={img3} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Bond</h2>
                                <p>The LooksYummy smart bonding platform allows our defi ecosystem to build its own, protocol owned liquidity with every transaction resulting in a more robust and stable asset infrastructure.</p>
                            </div>
                            <div>
                                <img src={img4} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Swap</h2>
                                <p>The Yummy Swap Exchange is a state-of-the-art AMM DEX that acts as the platform's native way to buy and sell tokens, including $LYT..</p>
                            </div>
                            <div>
                                <img src={img5} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Farm</h2>
                                <p>The Yummy Farm is a high-performance capital management protocol that allows $LYT holders to focus on what they love while they earn by automatically deploying highly efficient earning strategies in AMM's (Automatic Market Makers), LP yield farming pools, and more without the need for manual intervention.</p>
                            </div>
                            <div>
                                <img src={img6} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Vault</h2>
                                <p>The Yummy Vault is a low-risk, high-reward contract that operates in a similar manner to a high-yield annuity and is designed to create sustainable passive income for $LYT holders while acting as a deflationary token sink mechanism for LYT rewards.</p>
                            </div>
                            <div>
                                <img src={img7} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products;