import "./index.css"
import theme from '../../../assets/images/nav_theme_btn.png'
import lang from '../../../assets/images/lang.png'
import logo from '../../../assets/images/logo.png'
import { useState} from "react";
import { AiOutlineCloseCircle} from 'react-icons/ai'
import { HiMenu } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { HashLink } from 'react-router-hash-link';
import Scrollspy from "react-scrollspy";

function Navbar() {
    const [click, setClick] = useState(true);
    const handleClick = () => setClick(!click);

    return (
        <>
            <div className="theme_container ">

                <div className="navbar_container">
                    <div className="logo_container">
                        <img src={logo} alt="" />
                    </div>
                    <div className="menu_container">
                        <span><HashLink to={'/#home'}>Home</HashLink></span>
                        <span><HashLink to={'/#community'}>Community</HashLink> </span>
                        <span><HashLink to={'/#about'}>About</HashLink></span>
                        <span><HashLink to={'/#services'}>Services</HashLink></span>
                        
                        <span><HashLink to={'/#roadmap'}>Road Map</HashLink></span>
                        <span><HashLink to={'/#team'}>Team</HashLink></span>
                        <span><HashLink to={'/#faq'}>FAQs</HashLink></span>
                    </div>
                    <div className="nav_right_container">
                        <div className="lang_container">
                            <img src={lang} alt="" />
                        </div>
                        <div className="theme_btn_container">
                            <img src={theme} alt="" />
                        </div>
                        <div className="theme_button">
                            <button>Connect Wallet</button>
                        </div>
                        <div onClick={handleClick} className="mobile_toggle_button">
                            <span><HiMenu /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>

                <div className="logo order-md-1">
                    <div className="theme_btn_container_mobile">
                        <img src={theme} alt="" />
                    </div>
                    <div className="fix-icon text-dark" onClick={handleClick}>
                        <IoClose />
                    </div>
                    {/* Mobile Menu close icon */}
                </div>

                <Scrollspy
                    className="navbar-nav  "
                    items={[
                        "home",
                        "community",
                        "about",
                        "services",
                        "roadmap",
                        "team",
                        "faq"
                    ]}
                    currentClassName="active"
                    offset={-90}
                >
                    <li className="nav-item">
                        <a href="#home" className="nav-link" onClick={handleClick}>Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="#community" className="nav-link" onClick={handleClick}>Community</a>
                    </li>
                    <li className="nav-item">
                        <a href="#about" className="nav-link" onClick={handleClick}> About</a>
                    </li>
                    <li className="nav-item">
                        <a href="#services" className="nav-link" onClick={handleClick}>Services </a>
                    </li>
                    <li className="nav-item">
                        <a href="#roadmap" className="nav-link" onClick={handleClick}>Road Map</a>
                    </li>
                    <li className="nav-item">
                        <a href="#team" className="nav-link" onClick={handleClick}>Team</a>
                    </li>
                    <li className="nav-item">
                        <a href="#faq" className="nav-link" onClick={handleClick}>FAQs</a>
                    </li>
                </Scrollspy>
            </div>
            {/* Mobile Menu End */}
        </>
    )
}

export default Navbar;