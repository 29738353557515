import "./index.css"
import img1 from "../../../assets/images/heading1.png"


function Service() {
    return (
        <div className="theme_container" id="services">
            <div className="service_container" id="services">

                <div className="theme_heading">
                    <img src={img1} alt="" />
                    <h1>Service</h1>
                </div>
                <div className="service_wrapper">
                    <div>
                        <h2>First time login:</h2>
                        <p>5 x 0.000010 LYT</p>
                    </div>
                    <div>
                        <h2>First Post:</h2>
                        <p>10 x 0.000010 LYT</p>
                    </div>
                    <div>
                        <h2>20 posts:</h2>
                        <p>20 x 0.000010 LYT</p>
                    </div>
                </div>
                <div className="service_wrapper">
                    <div>
                        <h2>Every 5 posts after #20 posts:</h2>
                        <p>1 x 0.000010 LYT</p>
                    </div>
                    <div>
                        <h2>Share Picture: </h2>
                        <p>1 x 0.000010 LYT</p>
                    </div>
                    <div>
                        <h2>After 10,000th User For Signing up:</h2>
                        <p>1 x 0.000010 LYT (Cap = 1,000,000)</p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Service;