import LeftMenu from "../components/mint/left_slider";
import NavbarM from "../components/mint/navbar";
import SubNavbar from "../components/mint/sub_menu_bar";

import LiquidityContainer from "../components/mint/your_liquidity";
import { Helmet } from "react-helmet";



function Liquidity() {
    return (
        <div className="">
            <Helmet>
                <title>
                    Looks Yummy Token | Liquidity
                </title>
            </Helmet>
            <NavbarM />
            <div className="theme_container_mint">
                <LeftMenu />
                <div className="mint_right_section">
                    <SubNavbar heading={"Liquidity"} menu_item={[{ name: 'Swap', link: '/swap' }, { name: 'Liquidity', link: '/liquidity' }]} currency={{ name: 'LYT/BUSD', value: '$10.32' }} />
                    <LiquidityContainer />
                </div>
            </div>
        </div>
    );
}

export default Liquidity;
