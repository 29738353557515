import "./index.css"
import img from "../../../assets/images/2.png"
import img1 from "../../../assets/images/heading.png"
import { HashLink } from 'react-router-hash-link';

function About() {
    return (
        <div className="theme_container" id="about">
            <div className="about_main_container">
                <div className="about_container">
                    <div className="theme_heading">
                        <img src={img1} alt="" />
                        <h1>Looks Yummy Token</h1>
                    </div>
                    <p className="about_p">$LYT is the native reward, governance and profit sharing asset of the LooksYummy ecosystem and serves as a deflationary internal reserve asset that can deployed to increase the capital efficiency & liquidity inside of the platform.</p>

                    <p className="about_p">LooksYummy provides a socially focused platform that enables new b2b and b2c markets to form around incentivizing foodies to dine in and interact with their favorite restaurants in exchange for passive income paid in our universal BEP-20 reward token $LYT.</p>

                    <p className="about_p">Our mission is to build wealth and income for diners and restaurants alike by providing the decentralized social and financial tools they need to succeed.</p>
                    <div className="about_button">
                        <button className="button_1">Buy LYT</button>
                        <button className="button_2"><HashLink to={'https://docs.looksyummyapp.com'}>Learn More</HashLink></button>
                    </div>
                </div>
                <div className="about_image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    )
}

export default About;