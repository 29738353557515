import "./index.css"
import img1 from "../../../assets/images/heading.png"
import img2 from "../../../assets/images/9.png"
import img3 from "../../../assets/images/r2.png"
import img4 from "../../../assets/images/r3.png"
import img5 from "../../../assets/images/r4.png"


function Roadmap() {
    return (
        <div className="theme_container">
            <div id="roadmap" className="roadmap_container">
                <div className="theme_heading">
                    <img src={img1} alt="" />
                    <h1>Roadmaps</h1>
                </div>
                <div className="roadmap_wrapper">
                    <div className="l_roadmap_section">
                        <div>
                            <img src={img2} alt="" />
                        </div>
                        <div className="roadmap_text">
                            <h1>Appetizers: MVP & Brand Development</h1>
                            <p>Since 2016 Looksyummy App was created based on the concept of a social media platform with a digital menu for restaurants where foodies “People that enjoy food worldwide” can have a dedicated platform to share images of food that they enjoy.</p>
<p>Objective: Develop a viable solution-oriented Brand Strategy and (State of the Art) MVP capable of connecting Defi investors with real-world utility and capital inflows. Superior Product + Defined Operations.</p>
                        </div>
                    </div>
                    <div className="roadmap_line_vertical"></div>
                    <div className="r_roadmap_section">
                        <div className="roadmap_text">
                            <h1 className="right_heading">First Course: Partners & Capitalization</h1>
                            <p>5 yrs of development to perfect the application, through trial and error, and feedback from users; as the technology/digital world became more innovative we’ve updated our application to go with the times.</p>
<p>Objective: Establish and nurture long-term synergistic relationships/partnerships with our community and the utilities that support them in an effort to facilitate a highly successful product launch. Build Leverage.</p>
                        </div>
                        <div>
                            <img src={img3} alt="" />
                        </div>
                    </div>
                    <div className="roadmap_line_vertical"></div>

                    <div className="l_roadmap_section">
                        <div>
                            <img src={img4} alt="" />
                        </div>
                        <div className="roadmap_text">
                            <h1>Second Course: Operations & Market Penetration</h1>
                            <p>LooksYummy Token was developed as a reward crypto token that user will gain from usage in the application and then can cash out for BNB Smart Chain or In Our E-Commerce Shop.</p>

<p>Objective: Establish and maintain consistent team processes and operations in an effort to sustain long-term market growth initiatives and opportunities. Build volume by onboarding more Defi users.</p>
                        </div>
                    </div>
                    <div className="roadmap_line_vertical"></div>

                    <div className="r_roadmap_section">
                        <div className="roadmap_text">
                            <h1 className="right_heading">Third Course: User Acquisition & Revenue 
Diversification</h1>
                            <p>LooksYummy Token which is an asset driven by users and restaurants interacting with our looksYummy food social media app.</p>
<p>Objective: Establish and nurture long-term synergistic relationships/partnerships with our community and the utilities that support them. Expand marketing channels in order to begin leveraging more traditional niche(s) that support more user growth.</p>
                        </div>
                        <div>
                            <img src={img5} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap;