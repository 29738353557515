import "./index.css"
import img from "../../../assets/images/1.png"
import img1 from "../../../assets/images/24.png"
import img2 from "../../../assets/images/25.png"
import img3 from "../../../assets/images/26.png"
import { NavLink } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';


function Hero() {
    return (
        <div className="theme_container hero_container" id="home">
            <div className="hero_image">
                <img src={img} alt="" />
            </div>
            <div className="hero_content">
                <h1>Eat, Share, Earn - Looksyummy</h1>
                <p>LooksYummy is an easy way to capture and share images of your meals from different restaurants that you visit. Whether it’s breakfast, brunch, lunch, dinner, or a late-night snack; sharing your images on LooksYummy will help you generate tokens that could turn into a free meal or cash out your tokens to send to your wallet for profit in either cryptocurrency or cash. We are giving back to our users simply because they deserve it by sharing their delicious meals.</p>

<p>Looks Yummy is a foodie first social media network that allows you to earn sustainable passive income by capturing and sharing images of your meals from differents restaurants that you visit.</p>

<p>Whether its’s breakfast, brunch, lunch, dinner, or a late night snack; share your images of your meal with friends to start stacking your $LYT tokens.</p>
                <h3>Download App :</h3>
                <div className="hero_sub_images">
                    <div className="download_images">
                    <a href='https://play.google.com/store/apps/details?id=com.looksyummyllc.looksyummy&hl=en_US&gl=US'><img src={img1} alt="" /> </a>
                    </div>
                    <div className="download_images">
                       <a href='https://apps.apple.com/us/app/looksyummy-food-photo-sharing/id1464182551'> <img src={img2} alt="" /></a>
                    </div>
                    <div className="download_images">
                        <NavLink to={'/portfolio'}>
                            <a href='/'><img src={img3} alt="" /></a>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Hero;