import LeftMenu from "../components/mint/left_slider";
import NavbarM from "../components/mint/navbar";
import SubNavbar from "../components/mint/sub_menu_bar";
import VaultCalculatorContainer from "../components/mint/vault_calculator";
import { Helmet } from "react-helmet";

function VaultCalculator() {
    return (
        <div className="">
            <Helmet>
                <title>
                    Looks Yummy Token | Vault | Calculator
                </title>
            </Helmet>
            <NavbarM />
            <div className="theme_container_mint">
                <LeftMenu />
                <div className="mint_right_section">
                    <SubNavbar
                        heading={'Vault Calculator'}
                        currency={{ name: 'LYT', value: '1.72M' }}
                    
                    />
                    <VaultCalculatorContainer />
                </div>
            </div>
        </div>
    )
}

export default VaultCalculator;
