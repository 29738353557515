import LeftMenu from "../components/mint/left_slider";
import NavbarM from "../components/mint/navbar";
import OptimizeContainer from "../components/mint/smartbond_optimize";
import SubNavbar from "../components/mint/sub_menu_bar";
import { Helmet } from "react-helmet";

function Optimize() {
    return (
        <div className="">
            <Helmet>
                <title>
                    Looks Yummy Token | Smart Bond | Optimize
                </title>
            </Helmet>
            <NavbarM />
            <div className="theme_container_mint">
                <LeftMenu />
                <div className="mint_right_section">
                    <SubNavbar
                        heading={"Smart Bond"}
                        menu_item={[
                            { name: 'Optimize', link: '/smartbond-optimize' },
                            { name: 'Market', link: '/smartbond-market' },
                            { name: 'Inverse Market (Coming soon)', link: 'none' }]}
                        currency={{ name: 'Bond Ratio', value: '50/50' }} />
                    <OptimizeContainer />
                </div>
            </div >
        </div >
    )
}

export default Optimize;
