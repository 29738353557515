import "./index.css"
import img from "../../../assets/images/heading.png"
import img1 from "../../../assets/images/6.png"
import img2 from "../../../assets/images/7.png"
import img3 from "../../../assets/images/8.png"

function Team() {
    return (
        <div id="team" className="team_wrapper">
            <div className="theme_heading">
                <img src={img} alt="" />
                <h1>Our Team</h1>
            </div>
            <div className="team_container">
                <div className="team_images">
                    <img src={img3} alt="" />
                </div>
                <div className="team_images">
                    <img src={img2} alt="" />
                </div>
                <div className="team_images">
                    <img src={img1} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Team;