import "./index.css"
import img from "../../../assets/images/mint/portfolio_graph.png"
import img1 from "../../../assets/images/mint/portfolio_graph2.png"
import img2 from "../../../assets/images/mint/open.png"
import img3 from "../../../assets/images/mint/round.png"
import img4 from "../../../assets/images/mint/farmcoin.png"





export default function PotfolioContainer() {

    var data = [
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        },
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        },
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }, {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }
        ,
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }, {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }
        ,
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }, {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }

    ]

    return (
        <div className="portfolio_main_container">
            <div className="portfolio_left_section">
                <div className="portfolio_content_card">
                    <span>LVT Balance</span>
                    <h2>134K LVT</h2>
                </div>
                <div className="portfolio_content_card">
                    <span>Portfolio Value</span>
                    <h3>36,345,34 USD</h3>
                </div>
                <div className="portfolio_content_card ">
                    <span>Daily Yield</span>
                    <h3 className="orange_color"> 3,34%</h3>
                </div>
                <div className="portfolio_content_card">
                    <span>Monthly Yield</span>
                    <h3>103,45%</h3>
                </div>
                <div className="portfolio_content_card">
                    <span>Avg Apy</span>
                    <h3>103,45%</h3>
                </div>
                <div className="portfolio_left_section_img">
                    <img src={img} alt="" />
                </div>
            </div>
            <div className="portfolio_middle_section">
                <div className="portfolio_middle_section_top">
                    <div className="portfolio_content_card">
                        <span>Claimable</span>
                        <h3>300.93</h3>
                    </div>
                    <div className="portfolio_content_card">
                        <span>Pending</span>
                        <h3>45.00</h3>
                    </div>
                    <div className="portfolio_content_card">
                        <span>Total LVT Earned</span>
                        <h2>345.93</h2>
                    </div>
                </div>
                <div className="portfolio_middle_section_bottom">
                    <div className="portfolio_content_card">
                        <span>Total Suply</span>
                        <h3 className="orange_color">125.00M</h3>
                    </div>
                    <div className="portfolio_content_card">
                        <span>F/D Market Cap</span>
                        <h3>45.00</h3>
                    </div>
                    <div className="portfolio_content_card">
                        <span>Circulating Supply</span>
                        <h3>3.75M: 3%</h3>
                    </div>
                    <div className="portfolio_content_card">
                        <span>Market Cap</span>
                        <h3>3.75M</h3>
                    </div>
                    <div className="portfolio_content_card">
                        <span>Burned Supply</span>
                        <h3>3.750M: 3%</h3>
                    </div>
                </div>

            </div>
            <div className="portfolio_right_section">
                <div className="portfolio_right_section_top">
                    <div className="portfolio_right_section_top_left">
                        <div className="portfolio_content_card">
                            <span>Earned This Week</span>
                            <h3 className="orange_color">+ 30.93 LYT</h3>
                            <p>+ 0.89% W/O/W</p>
                        </div>
                        <div className="portfolio_content_card">
                            <span>Earnings Breakdown</span>
                            <div>
                                <img src={img3} alt="" />
                            </div>
                        </div>
                        <div className="portfolio_content_card">
                            <span>Fuly Vested In</span>
                            <h3 className="orange_color">15D:24HR:0M:0S</h3>
                        </div>
                    </div>
                    <div className="portfolio_right_section_top_right">
                        <img src={img1} alt="" />
                    </div>
                </div>
                <div className="portfolio_right_section_bottom">
                    <table>
                        <tr className="portfolio_top_heading">
                            <th>My Bonds</th>
                            <th>Claimable</th>
                            <th>Pending</th>
                            <th colSpan={2}>Vesting Lock Up</th>
                        </tr>
                        {
                            ['', '', '', '', '', '', '', '', ''].map(() => {
                                return (
                                    <>
                                        <tr className="portfolio_bottom_text">
                                            <td><img src={img4} alt="" />
                                                <span>BUSD-LYT LP Band</span></td>
                                            <td>63,031.0 LYT</td>
                                            <td>63,031.0 LYT</td>
                                            <td><p>3 d 2 h</p></td>
                                            <td><button>Claim</button></td>
                                        </tr>
                                        <tr className="border_bottom_tr">
                                            <td colSpan={7}></td>
                                        </tr>
                                    </>
                                )
                            })
                        }

                    </table>

                </div>

            </div>

        </div>
    )
}
