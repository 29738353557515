import "./index.css"
import img1 from "../../../assets/images/5.png"
import img from "../../../assets/images/heading1.png"

function Buy() {
    return (
        <div className="theme_container">
            <div className="how_container" >
                <div className="how_content">
                    <div className="theme_heading">
                        <img src={img} alt="" />
                        <h1>How To Buy</h1>
                    </div>
                    <div className="how_text">
                        <h1>#1</h1>
                        <p>Buy BNB Smart Chain</p>
                    </div>
                    <div className="how_text">
                        <h1>#2</h1>
                        <p>Go to Pancake Swap</p>
                    </div>
                    <div className="how_text">
                        <h1>#3</h1>
                        <p>Search using the contract address</p>
                    </div>
                    <div className="how_text">
                        <h1>#4</h1>
                        <p>Buy LYT</p>
                    </div>
                </div>
                <div className="how_image">
                    <img src={img1} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Buy;