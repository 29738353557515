import "./index.css"
import { useState} from "react";
import { AiOutlineCloseCircle} from 'react-icons/ai'

export default function Warning() {
    const [open, setOpen] = useState(true)

    return (
        <div>
            {
                open ?
                    <div className='top_warning_container'>
                        <p><span style={{ color: '#FF0000', fontWeight: 700 }}>PHISHING WARNING: </span> please make sure you're visiting <span style={{ fontWeight: 700 }}>https://www.looksyummyapp.com/</span> - check the URL carefully</p>
                        <span onClick={() => setOpen(false)} className='close_wrapper'><AiOutlineCloseCircle /></span>
                    </div>
                    :
                    null
            }
        </div>
    )
}