import AppRouter from './config/router';
import { Helmet } from "react-helmet";
import Warning from './components/Home/top_navbar_warning';

function App() {
  return (
    <>
      <Helmet>
        <title>Looks Yummy Token</title>
        <meta
          name="keywords"
          content="nft, crypto, looks-yummy-token, looks yummy token"
        />
        <meta
          name="description"
          content="Looks Yummy Token"
        />
      </Helmet>
      <Warning />
      <AppRouter />
    </>
  );
}

export default App;
