import "./index.css"
import img1 from "../../../assets/images/mint/1.png"
import img2 from "../../../assets/images/mint/2.png"


function LiquidityContainer() {
    return (
        <div className="liquidity_inputs_container">
            <div className="liquidity_heading">
                <h3>Your Liquidity</h3>
                <div className="liquidity_head_icons">
                    <span><img src={img1} alt="" /></span>
                    <span><img src={img2} alt="" /></span>
                </div>
            </div>
            <div className="liquidity_msg_boxr">
                <p>Connect wallet for view your liquidity</p>
            </div>
            <div className="mint_theme_button">
                <button>Calculate</button>
            </div>
        </div>
    );
}

export default LiquidityContainer;