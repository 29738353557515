import LeftMenu from "../components/mint/left_slider";
import NavbarM from "../components/mint/navbar";
import SubNavbar from "../components/mint/sub_menu_bar";
import VaultDashboardContainer from "../components/mint/vault_dashboard";
import { Helmet } from "react-helmet";

function VaultDashboard() {
    return (
        <div className="">
            <Helmet>
                <title>
                    Looks Yummy Token | Vault
                </title>
            </Helmet>
            <NavbarM />
            <div className="theme_container_mint">
                <LeftMenu />
                <div className="mint_right_section">
                    <SubNavbar
                        heading={"Vault"}
                        currency={{ name: 'LYT', value: '1.72M' }} />
                    <VaultDashboardContainer />
                </div>
            </div>
        </div>
    )
}

export default VaultDashboard;
