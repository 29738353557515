import About from "../components/Home/about";
import Community from "../components/Home/community";
import Faq from "../components/Home/faq";
import Footer from "../components/Home/footer";
import Hero from "../components/Home/Hero";
import Buy from "../components/Home/how_to_buy";
import Navbar from "../components/Home/navbar";
import Team from "../components/Home/team";
import Why from "../components/Home/why_lyt";
import Touch from "../components/Home/get_touch";
import Products from "../components/Home/products";
import Features from "../components/Home/features";
import Roadmap from "../components/Home/roadmap";
import Service from "../components/Home/service";
import { Helmet } from "react-helmet";

function Home() {
    return ( 
        <div className="main_container">
            <Navbar />
            <Hero />
            <Community />
            <About />
            <Why />
            <Products/>
            <Features/>
            <Service/>
            <Roadmap/>
            <Buy />
            <Team />
            <Faq />
            <Touch />
            <Footer />
        </div>
    );
}

export default Home;
