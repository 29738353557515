import "./index.css"
import img from "../../../assets/images/mint/swapgraph.png"
import img1 from "../../../assets/images/mint/swap1.png"
import img2 from "../../../assets/images/mint/1.png"
import img3 from "../../../assets/images/mint/2.png"
import img4 from "../../../assets/images/mint/swap2.png"


function SwapContainer() {
    return (
        <div className="farm_main_container">
            <div className="farm_graph_image">
                <img src={img} alt="" />
            </div>
            <div className="swap_sub_container">
                <div className="swap_icons">
                    <div>
                        <img src={img1} alt="" />
                    </div>
                    <div>
                        <img src={img2} alt="" />
                        <img src={img3} alt="" />
                        <img src={img4} alt="" />
                    </div>
                </div>
                <div className="swap_text">
                    <h2>From</h2>
                    <div className="">
                        <input type="text" placeholder="0" />
                    </div>
                    <span>Balance : 0</span>
                </div>
                <div className="swap_text">
                    <h2>To</h2>
                    <div className="">
                        <input type="text" placeholder="0" />
                    </div>
                    <span>Balance : 0</span>
                </div>
                <div className="mint_theme_button">
                    <button>Lorem Ipsum</button>
                </div>
            </div>
        </div>
    )
}

export default SwapContainer;