import "./index.css"
import img from "../../../assets/images/mint/m_coin.png"
import img1 from "../../../assets/images/mint/m_logo.png"
import img2 from "../../../assets/images/mint/open.png"



function MarketContainer() {

    var data = [
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        },
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        },
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }, {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }
        ,
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }, {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }
        ,
        {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }, {
            token_value: '$13.02',
            discount: '0.04%',
            capacity: ['64 K DAI', '63,031.0 LYT'],
        },
        {
            token_value: '$$12.65',
            discount: '-2,89%',
            capacity: ['104 K DAI', '8,241.706 LYT'],
        }

    ]

    return (
        <div className="market_container">
            <div className="market_heading_wrapper">
                <div className="m_heading_wrapper">
                    <span>Treasury Balance</span>
                    <h2>$300,000,000</h2>
                </div>
                <div className="m_heading_wrapper">
                    <span>LYT Price</span>
                    <h2>$1.75</h2>
                </div>
            </div>

            <div className="market_list_wrapper">

                <table className="market_table">
                    <tr>
                        <th>Token</th>
                        <th>Payout Asset</th>
                        <th>Token</th>
                        <th>Discount</th>
                        <th>Capacity</th>
                        <th>Band Ratio</th>
                        <th></th>
                    </tr>
                    {data.map((v, i) => {
                        return (<><tr id={i} className="market_table_row">

                            <td className="payout_item">
                                <img src={img} alt="" />
                                <span>BUSD</span>
                                <img src={img2} alt="" />
                            </td>
                            <td className="payout_item">
                                <img src={img1} alt="" />
                                <span>LYT</span>
                                <img src={img2} alt="" />
                            </td>
                            <td className="token_third_item">
                                <span>{v.token_value}</span>
                            </td>
                            <td className={v.discount.slice(0, 1) == '-' ? 'discount_item_red' : 'discount_item_green'}>
                                <span>{v.discount}</span>
                            </td>
                            <td className="capacity_item">
                                <h2>{v.capacity[0]}</h2>
                                <p>{v.capacity[1]}</p>
                            </td>
                            <td className="capacity_item">
                                <h2>50/50</h2>
                                <p>LYT/BUSD</p>
                            </td>
                            <td className="bond_button">
                                <button>Bond</button>
                            </td>
                        </tr>
                            <tr className="border_bottom_tr">
                                <td colSpan={7}></td>
                            </tr>
                        </>
                        )
                    })}
                </table>
            </div>
        </div>
    )
}

export default MarketContainer;