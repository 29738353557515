import './index.css'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import img from "../../../assets/images/heading.png"


function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div className='faq_header' onClick={decoratedOnClick}>
      <h3>{children} </h3>
      <button className={isCurrentEventKey ? 'b_active' : ''}> + </button>
    </div>

  );
}

function Faq() {
  return (
    <div id='faq' className="faq_container">
      <div className='theme_heading'>
        <img src={img} alt="" />
        <h1>FAQ</h1>
      </div>
      <div>
        <Accordion className='faq_wrapper' defaultActiveKey="">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="0"> 1. What is Lookyummy App ?</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>The LooksYummy App is the primary tool of the $LYT ecosystem and allows foodies to earn $LYT by performing positive platform actions such as sharing photos of their favorite meals, liking photos, commenting on posts, referring new users, and more. Our innovative e-comm social network enables new b2b and b2c markets to form around 2-way customer relationships.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="12"> 2. How do I setup this App ?</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="12">
              <Card.Body>Getting started is easy! Download the LooksYummy App from the Apple or Google Play store, set up a quick account, and start earning by sharing your favorite meals!
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="1"> 3. Does it cost anything to become a member ?</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>No, registration and membership are always 100% free!</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="5">4. What is yout policy regarding privacy ?</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>Our privacy and security policies can be viewed by visiting the following link:
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="6">5. Are there more help resources available ?</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body>We would love to take your questions - feel free to jump into our Telegram channel to receive additional support with the app as well as connect with the LooksYummy community from around the world.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>



  )
}

export default Faq;