import LeftMenu from "../components/mint/left_slider";
import Navbar from "../components/mint/navbar";
import SubNavbar from "../components/mint/sub_menu_bar";
import { Helmet } from "react-helmet";
import PotfolioContainer from "../components/mint/portfolio";

function Mint() {
    return (
        <div className="mint_container">
            <Helmet>
                <title>
                    Looks Yummy Token | Portfolio
                </title>
            </Helmet>
            <Navbar />

            <div className="theme_container_mint">
                <LeftMenu />
                <div className="mint_right_section">
                    <PotfolioContainer />
                </div>
            </div>
        </div>

    );
}

export default Mint;