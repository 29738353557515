import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Farm from "../pages/farm";
import Home from "../pages/home";
import Liquidity from "../pages/information_liquidity";
import Swap from "../pages/information_swap";
import Portfolio from "../pages/portfolio";
import Market from "../pages/smartbond_market";
import Optimize from "../pages/smartbond_optimize";
import VaultCalculator from "../pages/vault_calculator";
import VaultDashboard from "../pages/vault_dashboard";


function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>} />
                {/* <Route path="/" element={<Portfolio />} /> */}
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/vault" element={<VaultDashboard />} />
                <Route path="/vault-calculator" element={<VaultCalculator />} />
                <Route path="/smartbond-optimize" element={<Optimize />} />
                <Route path="/smartbond-market" element={<Market/>} />
                <Route path="/swap" element={<Swap/>} />
                <Route path="/liquidity" element={<Liquidity />} />
                <Route path="/farm" element={<Farm/>} />
            </Routes>
        </Router>
    )
}

export default AppRouter;