import "./index.css"
import img from "../../../assets/images/mint/coin.png"
import arr from "../../../assets/images/mint/sm_bond_arr.png"


function OptimizeContainer() {
    return (

        <div className="optimize_container">
            <div className="optimize_head">
                <div>
                    <img src={img} alt="" />
                    <span>BUSD</span>
                </div>
                <div className="">
                    <img src={arr} alt="" />
                </div>
            </div>
            <div className="liquidity_discount">
                <p>Discount</p>
                <h2>6%</h2>
            </div>
            <div className="liquidity_content">
                <div><span>Available LYT :</span> <span>300.000</span></div>
                <div><span>Vesting Period : </span> <span>7 Days</span> </div>
            </div>
            <div className="mint_theme_button">
                <button>Bond</button>
            </div>
        </div>
    );
}

export default OptimizeContainer;