import './index.css'
function VaultCalculatorContainer() {
    return (
        <div className="vault_inputs_container">
            <div className="vault_input_wrapper">
                <label htmlFor="invest">Initial LYT Investment</label>
                <input readOnly id="invest" value={'1000 LYT'} type="text" />
            </div>

            <div className="vault_input_wrapper">
                <label htmlFor="rate">Rate Of Investment</label>
                <input readOnly id="rate" value={'1.0%'} type="text" />
            </div>
            <div className="vault_input_wrapper">
                <label htmlFor="term">Term Lenght</label>
                <input readOnly id="term" value={'365 Days'} type="text" />
            </div>
            <div className="mint_theme_button">
                <button>Calculate</button>
            </div>
        </div>

    );
}

export default VaultCalculatorContainer;
