import "./index.css"
import img from "../../../assets/images/mint/m_coin.png"
import img1 from "../../../assets/images/mint/farmcoin.png"
import mail from "../../../assets/images/mint/farm_bnb.png"
import bnb from "../../../assets/images/mint/farm_mail.png"
import arr from "../../../assets/images/mint/arrow_farm_heading.png"
import token from "../../../assets/images/mint/Binance_farm.png"
import farmcoin from "../../../assets/images/mint/farmcoin.png"





function FarmContainer() {

    return (
        <div className="farm_container">
            <div className="farm_heading">
                <h1>Featured Farm</h1>
            </div>
            <div className="farm_sub_container">
                <div className="farm_deposit">
                    <span>Deposits</span>
                    <p>
                        <img src={img} alt="" />
                        BUSD
                    </p>
                </div>
                <div className="farm_deposit">
                    <span>Yummy Apply</span>
                    <p className="orange">
                        326.30%
                    </p>
                </div>
                <div className="farm_deposit">
                    <span>Earn By Stalking</span>
                    <p>
                        <img src={img1} alt="" />
                        BUSD - LYT LP
                    </p>
                </div>
            </div>
            <div className="farm_table_main_container">
                <div className="filter_container">
                    <h2>Filter</h2>
                    <div className="filter_input">
                        <input type="text" placeholder="Search" />
                    </div>
                    <div className="filter_sub_text">
                        <p>Chain</p>
                        <p><img src={bnb} alt="" /> All</p>
                        <p><img src={mail} alt="" /> BNB</p>
                    </div>
                </div>

                <div className="farm_list_wrapper">
                    <table className="farm_table">
                        <tr>
                            <th>Token Chain TVL <img src={arr} alt="" /></th>
                            <th>Chain</th>
                            <th>APY Daily APR <img src={arr} alt="" /></th>
                            <th>Balance Deposit</th>
                        </tr>


                        <tr className="farm_table_row">
                            <td>
                                <div className="token_chain_item">
                                    <div><img src={farmcoin} alt="" /></div>
                                    <div className="token_chain_text">
                                        <span>New</span>
                                        <h3>BUSD - LYT LP</h3>
                                        <div className="token_chain_subtext">
                                            <p>Yummy Swap</p>
                                            <span>TVL $0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="bnb_item"><img src={token} alt="" /> BNB</td>
                            <td className="daily_item">467.43%
                                <br />
                                23.00%
                            </td>
                            <td className="daily_item">$-
                                <br />
                                $-</td>

                        </tr>
                        <tr className="border_bottom_tr">
                            <td colSpan={7}></td>
                        </tr>
                        <tr className="farm_table_row">
                            <td>
                                <div className="token_chain_item">
                                    <div><img src={farmcoin} alt="" /></div>
                                    <div className="token_chain_text">
                                        <span>New</span>
                                        <h3>BUSD - LYT LP</h3>
                                        <div className="token_chain_subtext">
                                            <p>Yummy Swap</p>
                                            <span>TVL $0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="bnb_item"><img src={token} alt="" /> BNB</td>
                            <td className="daily_item">467.43%
                                <br />
                                23.00%
                            </td>
                            <td className="daily_item">$-
                                <br />
                                $-</td>

                        </tr>
                        <tr className="border_bottom_tr">
                            <td colSpan={7}></td>
                        </tr>
                        <tr className="farm_table_row">
                            <td>
                                <div className="token_chain_item">
                                    <div><img src={farmcoin} alt="" /></div>
                                    <div className="token_chain_text">
                                        <span>New</span>
                                        <h3>BUSD - LYT LP</h3>
                                        <div className="token_chain_subtext">
                                            <p>Yummy Swap</p>
                                            <span>TVL $0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="bnb_item"><img src={token} alt="" /> BNB</td>
                            <td className="daily_item">467.43%
                                <br />
                                23.00%
                            </td>
                            <td className="daily_item">$-
                                <br />
                                $-</td>

                        </tr>
                        <tr className="border_bottom_tr">
                            <td colSpan={7}></td>
                        </tr>
                        <tr className="farm_table_row">
                            <td>
                                <div className="token_chain_item">
                                    <div><img src={farmcoin} alt="" /></div>
                                    <div className="token_chain_text">
                                        <span>New</span>
                                        <h3>BUSD - LYT LP</h3>
                                        <div className="token_chain_subtext">
                                            <p>Yummy Swap</p>
                                            <span>TVL $0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="bnb_item"><img src={token} alt="" /> BNB</td>
                            <td className="daily_item">467.43%
                                <br />
                                23.00%
                            </td>
                            <td className="daily_item">$-
                                <br />
                                $-</td>

                        </tr>
                        <tr className="border_bottom_tr">
                            <td colSpan={7}></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default FarmContainer;
