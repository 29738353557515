import "./index.css"

function VaultDashboardContainer() {
    return (
        <div className="vault_dashboard_container">
            <div className="vault_dashboard_left">
                <div className="vd_card big_">
                    <p>Address</p>
                    <h3>Not Connected</h3>
                </div>
                <div className="vault_dashboard_content">
                    <div className="vd_card">
                        <p>Available</p>
                        <h3>0.0 LYT</h3>
                    </div>
                    <div className="vd_card">
                        <p>Deposits</p>
                        <h3>0.0 LYT</h3>
                    </div>
                    <div className="vd_card">
                        <p>Claimed</p>
                        <h3>0.0 LYT</h3>
                    </div>
                    <div className="vd_card">
                        <p>Raferal Rewards</p>
                        <h3>0.0 LYT</h3>
                    </div>
                    <div className="vd_card">
                        <p>Max Payout</p>
                        <h3>0.0 LYT</h3>
                    </div>
                    <div className="vd_card">
                        <p>Direct Referal/Team</p>
                        <h3>N/A/N/A</h3>
                    </div>
                </div>
                <div className="mint_theme_button">
                    <button>Recompound</button>
                </div>
            </div>
            <div className="vault_dashboard_right">


                <div className="total_deposit">
                    <div className="animated_line_wrapper">
                        <div className="animated_line" style={{width: '35%'}}> </div>
                    </div>
                    <p>Total Deposit Limit % : <span> 35%</span></p>
                </div>
                <div className="deposit_container">
                    <h3>Deposit</h3>

                    <div className="deposit_input_wrapper">
                        <div className="deposit_input_label">
                            <label htmlFor="amount">Amount</label>
                            <span>LYT Balance : 0.000</span>
                        </div>
                        <div className="deposit_input_value">
                            <input readOnly id='amount' value={'0.000 LYT'} type="text" name="" />
                        </div>
                    </div>

                    <div className="deposit_guidline">
                        <p>A minimum of 1 LYT required for deposit*</p>
                        <p>A 15% tax is changed on Deposits*</p>
                        <p>A 15% tax is changed on Withdraws*</p>
                        <p>A 7.5% tax is changed on Compound*</p>
                        <p>Ensure some BNB remains in your account for gas*</p>
                    </div>
                    <div className="mint_theme_button">
                        <button>Deposit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VaultDashboardContainer;