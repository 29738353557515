import "./index.css"
import img1 from "../../../assets/images/mint/portfolio.png"
import img2 from "../../../assets/images/mint/info.png"
import img3 from "../../../assets/images/mint/smart.png"
import img4 from "../../../assets/images/mint/vault.png"
import img5 from "../../../assets/images/mint/farm.png"
import img7 from "../../../assets/images/mint/portfolio1.png"
import img8 from "../../../assets/images/mint/info1.png"
import img9 from "../../../assets/images/mint/smart1.png"
import img10 from "../../../assets/images/mint/vault1.png"
import img11 from "../../../assets/images/mint/farm1.png"
import img6 from "../../../assets/images/mint/lang.png"
import theme from "../../../assets/images/theme_btn.png"

import { BsInstagram, BsFacebook, BsTwitter } from 'react-icons/bs'
import { FaTelegramPlane } from 'react-icons/fa'
import { ImEarth } from 'react-icons/im'
import { NavLink, useLocation } from "react-router-dom"


export default function LeftMenu() {
    const location = useLocation().pathname;
    return (
        <>
            <div className="left_menu_container">
                <div>
                    <NavLink to={'/portfolio'} className={({ isActive }) => (isActive ? 'active_menu' : 'in_active_menu')}>
                        <div className="left_menu_items">
                            <img src={location == "/portfolio" ? img7 : img1} alt="" />
                            <span>
                                Portfolio
                            </span>
                        </div>
                    </NavLink>
                    <NavLink to={'/swap'} className={({ isActive }) => (isActive || location == '/liquidity' ? 'active_menu' : 'in_active_menu')}>
                        <div className="left_menu_items">
                            <img src={location == "/liquidity" || location == "/swap" ? img8 : img2} alt="" />
                            <span>
                                Information
                            </span>
                        </div>
                    </NavLink>
                    <NavLink to={'/smartbond-optimize'} className={({ isActive }) => (isActive || location == '/smartbond-market' ? 'active_menu' : 'in_active_menu')}>
                        <div className="left_menu_items">
                            <img src={location == "/smartbond-optimize" || location == "/smartbond-market" ? img9 : img3} alt="" />
                            <span>
                                Smart Bond
                            </span>
                        </div>
                    </NavLink>
                    <NavLink to={'/vault'} className={({ isActive }) => (isActive ? 'active_menu' : 'in_active_menu')}>
                        <div className="left_menu_items">
                            <img src={location == "/vault" ? img10 : img4} alt="" />
                            <span>
                                Vault
                            </span>
                        </div>
                    </NavLink>
                    <NavLink to={'/farm'} className={({ isActive }) => (isActive ? 'active_menu' : 'in_active_menu')}>
                        <div className="left_menu_items">
                            <img src={location == "/farm" ? img11 : img5} alt="" />
                            <span>
                                Farm
                            </span>
                        </div>
                    </NavLink>
                </div>
                <div className="line_break"></div>

                <div className="left_menu_items left_language">
                    <img src={img6} alt="" />
                    <span>English</span>
                </div>
                <div className="left_menu_icons">
                    <span><BsInstagram /></span>
                    <span><BsFacebook /></span>
                    <span><BsTwitter /></span>
                    <span><FaTelegramPlane /></span>
                    <span><ImEarth /></span>
                </div>
                <div className="left_menu_contact">
                    <p><span> Email : </span><a href="mailto:info@looksyummyapp.com"> info@looksyummyapp.com</a> </p>
                    <p><span>Phone : </span><a href="tel:954-870-1178"> 954-870-1178</a> <br /> <a href="tel:954-870-1187">954-870-1187</a></p>
                </div>
            </div>

            <div className="mobile_footer_mint">
                <div className="left_menu_items left_language">
                    <img src={img6} alt="" />
                    <span>English</span>
                </div>
                <div className="left_menu_icons">
                    <span><BsInstagram /></span>
                    <span><BsFacebook /></span>
                    <span><BsTwitter /></span>
                    <span><FaTelegramPlane /></span>
                    <span><ImEarth /></span>
                </div>
                <div className="left_menu_contact">
                    <p><span> Email : </span><a href="mailto:info@looksyummyapp.com"> info@looksyummyapp.com</a> </p>
                    <p><span>Phone : </span><a href="tel:954-870-1178"> 954-870-1178</a> <a href="tel:954-870-1187">954-870-1187</a></p>
                </div>
                <div className="f_theme_button">
                    <img src={theme} alt="" />
                </div>
            </div>

            <div className="bottom_navigation">
                <NavLink to={'/portfolio'} className={({ isActive }) => (isActive ? 'active_menu' : 'in_active_menu')}>
                    <div className="bottom_menu_item">
                        <img src={location == "/portfolio" ? img7 : img1} alt="" />
                        <span>
                            Portfolio
                        </span>
                    </div>
                </NavLink>
                <NavLink to={'/swap'} className={({ isActive }) => (isActive || location == '/liquidity' ? 'active_menu' : 'in_active_menu')}>
                    <div className="bottom_menu_item">
                        <img src={location == "/liquidity" || location == "/swap" ? img8 : img2} alt="" />
                        <span>
                            Information
                        </span>
                    </div>
                </NavLink>
                <NavLink to={'/smartbond-optimize'} className={({ isActive }) => (isActive || location == '/smartbond-market' ? 'active_menu' : 'in_active_menu')}>
                    <div className="bottom_menu_item">
                        <img src={location == "/smartbond-optimize" || location == "/smartbond-market" ? img9 : img3} alt="" />
                        <span>
                            Smart Bond
                        </span>
                    </div>
                </NavLink>
                <NavLink to={'/vault'} className={({ isActive }) => (isActive ? 'active_menu' : 'in_active_menu')}>
                    <div className="bottom_menu_item">
                        <img src={location == "/vault" ? img10 : img4} alt="" />
                        <span>
                            Vault
                        </span>
                    </div>
                </NavLink>
                <NavLink to={'/farm'} className={({ isActive }) => (isActive ? 'active_menu' : 'in_active_menu')}>
                    <div className="bottom_menu_item">
                        <img src={location == "/farm" ? img11 : img5} alt="" />
                        <span>
                            Farm
                        </span>
                    </div>
                </NavLink>
            </div>

        </>
    )
}