import "./index.css"
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useState, useContext, useEffect } from "react";
import logo from "../../../assets/images/f_logo.png"
import mlogo from "../../../assets/images/mint/mobile_logo.png"
import img1 from "../../../assets/images/signin.png"
import img2 from "../../../assets/images/wallet.png"
import img3 from "../../../assets/images/nav_theme_btn.png"


export default function NavbarM() {
    const [open, setOpen] = useState(true)
    return (
        <div>
            <div className="mint_navbar_container_wrapper">
                <div className="mint_navbar_container">
                    <div className="logo_container">
                        <img className="dekstop_logo" src={logo} alt="" />
                        <img className="mobile_logo" src={mlogo} alt="" />
                    </div>
                    <div className="mint_nav_right">
                        <div className="nav_button1">
                            <button><img src={img1} alt="" />Sign In</button>
                        </div>
                        <div className="nav_button2">
                            <button><img src={img2} alt="" /> Wallet</button>
                        </div>
                        <div className="theme_change">
                            <img src={img3} alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}