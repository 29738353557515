import "./index.css"
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { GrLocation } from 'react-icons/gr';
import icon from '../../../assets/images/heading.png'
function Touch() {
    return (
        <div className="theme_container">
            <div className="touch_container">
                <div>

                    <div className="theme_heading">
                        <img src={icon} alt="" />
                        <h1>Get In Touch</h1>
                    </div>
                    <div className="get_icons">
                        <div className="get_icons_text">
                            <p><BsTelephone /></p>
                            <span><span className="bold_text">Phone :</span> (954) 870-1178 / (954) 870-1187</span>
                        </div>
                        <div className="get_icons_text">
                            <p><AiOutlineMail /></p>
                            <span><span className="bold_text">Email :</span> info@looksyummyapp.com</span>
                        </div>
                        <div className="get_icons_text">
                            <p><GrLocation /></p>
                            <span><span className="bold_text">Address:</span> South Florida</span>
                        </div>
                    </div>

                </div>
                <div className="inputs_container">
                    <div className="input_wrapper">
                        <label htmlFor="name">Name<span>*</span></label>
                        <input type="text" name="name" id="name" />
                    </div>

                    <div className="input_wrapper">
                        <label htmlFor="email">Email<span>*</span></label>
                        <input type="email" name="email" id="email" />
                    </div>

                    <div className="input_wrapper">
                        <label htmlFor="message">Messages<span>*</span></label>
                        <textarea name="message" id="message" cols="30" rows="4"></textarea>
                    </div>
                    <div className="touch_button">
                        <button>Send</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Touch;