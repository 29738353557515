import "./index.css"
import img from "../../../assets/images/heading.png"
import img1 from "../../../assets/images/21.png"
import img2 from "../../../assets/images/22.png"
import img3 from "../../../assets/images/23.png"

function Why() {
    return (
        <div className="theme_container">
            <div className="why_wrapper">
                <div className="theme_heading">
                    <img src={img} alt="" />
                    <h1>Why  Looks Yummy ?</h1>
                </div>
                <div className="why_container">
                    <div className="why_image_container">
                        <div className="why_images">
                            <img src={img1} alt="" />
                        </div>
                        <div className="why_images_text">
                            <h2>#01</h2>
                            <h6>Eat</h6>
                            <p>Visit your favorite restaurants with your friends and family - don’t forget to snap a picture of your meal!</p>
                        </div>
                    </div>
                    <div className="why_image_container">
                        <div className="why_images">
                            <img src={img2} alt="" />
                        </div>
                        <div className="why_images_text">
                            <h2>#02</h2>
                            <h6>Share</h6>
                            <p>Share your meal and perform positive actions in the Looks Yummy app to earn $LYT tokens for your loyalty!</p>
                        </div>
                    </div>
                    <div className="why_image_container">
                        <div className="why_images">
                            <img src={img3} alt="" />
                        </div>
                        <div className="why_images_text">
                            <h2>#03</h2>
                            <h6>Earn</h6>
                            <p>Utilize our powerful Yummy protocols to compound your earnings and build financial freedom for the long term!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Why;