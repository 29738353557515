import { Helmet } from "react-helmet";
import FarmContainer from "../components/mint/farm";
import LeftMenu from "../components/mint/left_slider";
import NavbarM from "../components/mint/navbar";
import SubNavbar from "../components/mint/sub_menu_bar";

function Farm() {
    return (
        <div className="">
            <Helmet>
                <title>
                    Looks Yummy Token | Farm
                </title>
            </Helmet>
            <NavbarM />
            <div className="theme_container_mint">
                <LeftMenu />
                <div className="mint_right_section">
                    <SubNavbar heading={"Farm"} currency={{ name: 'LYT', value: '1.72M' }} />
                    <FarmContainer/>
                </div>
            </div>
        </div>
    );
}

export default Farm;
