import "./index.css"
import img1 from "../../../assets/images/heading.png"
import img from "../../../assets/images/3.png"
import img2 from "../../../assets/images/14.png"
import img3 from "../../../assets/images/12.png"
import img4 from "../../../assets/images/11.png"
import img5 from "../../../assets/images/10.png"


function Features() {
    return (
        <div className="theme_container">
            <div className="product_container feature_container">
                <div className="product_content">
                    <div className="theme_heading">
                        <img src={img1} alt="" />
                        <h1>Our App Features</h1>
                    </div>
                    <div className="product_list">
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Tag meal</h2>
                                <p>Tag a restaurant with the meal you had there. This way each meal will have a restaurant attached to it and people can come to the restaurant to have it using the app.</p>
                            </div>
                            <div>
                                <img src={img2} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Browse Meals</h2>
                                <p>Browse hundreds of meals posted by a vast community of our users.</p>
                            </div>
                            <div>
                                <img src={img3} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Follow Users</h2>
                                <p>Follow your most favorite foodie & meal contributor and get instant notification whenever a new meal is posted by the user.</p>
                            </div>
                            <div>
                                <img src={img4} alt="" />
                            </div>
                        </div>
                        <div className="product_wrapper">
                            <div className="product_text">
                                <h2>Post</h2>
                                <p>Post new meals from restaurant you visit.</p>
                            </div>
                            <div>
                                <img src={img5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product_image feature_image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Features;