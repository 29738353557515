import "./index.css"
import { BsInstagram, BsFacebook, BsTwitter } from 'react-icons/bs'
import { FaTelegramPlane } from 'react-icons/fa'
import { ImEarth } from 'react-icons/im'
import LOGO from '../../../assets/images/f_logo.png'
import theme_btn from '../../../assets/images/theme_btn.png'
import { HashLink } from 'react-router-hash-link';

function Footer() {
    return (
        <div className="footer_container">
            <div className="theme_container">
                <div className="first_section_footer">
                    <div className="f_left_container">
                        <div className="f_logo_container">
                            <HashLink to={'/#'}><img src={LOGO} alt='footer logo' /></HashLink>

                        </div>
                        <p>Discover Looksyummy Token, the revolutionary defi utility platform developed by Looksyummy LLC. Acquire equity and delve into the world of digital currencies with our innovative token, designed to provide growth and value. Please note that investing in Looksyummy Token or any cryptocurrency involves inherent risks, and the performance and success of the token are subject to market volatility.
                        </p>
                    </div>
                    <div className="footer_menu">
                        <h3>Pages</h3>
                        <div className="footer_menu_wrappper">
                            <div className="footer_sub_menu">
                                <div><HashLink to={'/#home'}>Home</HashLink></div>
                                <div><HashLink to={'/#community'}>Community</HashLink> </div>
                                <div><HashLink to={'/#about'}>About</HashLink></div>
                            </div>
                            <div className="footer_sub_menu">
                                <div> <HashLink to={'/#services'}>Services</HashLink></div>
                                <div> <HashLink to={'/#roadmap'}>Road Map</HashLink></div>
                                <div> <HashLink to={'/#team'}>Team</HashLink></div>
                            </div>
                            <div className="footer_sub_menu">
                                <div><HashLink to={'/#faq'}>FAQs</HashLink></div>
                                <div><HashLink to={'https://docs.looksyummyapp.com'}>Whitepaper</HashLink></div>
                                <div><HashLink to={''}>Contact</HashLink></div>

                            </div>
                        </div>
                    </div>
                    <div className="footer_left">
                        <div>
                            <div className="icons_container">
                                <span><HashLink to={'https://instagram.com/looksyummyapp?igshid=MzRlODBiNWFlZA=='}><BsInstagram /></HashLink></span>
                                <span><HashLink to={'http://facebook.com/looksyummyapp'}><BsFacebook /></HashLink></span>
                                <span><HashLink to={'https://twitter.com/LooksYummyApp?t=lxk482mP-klWlCbfSGo6DA&s=09'}><BsTwitter /></HashLink></span>
                                <span><HashLink to={'http://t.me/looksyummytokenofficial'}><FaTelegramPlane /></HashLink></span>
                                <span><HashLink to={'/'}><ImEarth /></HashLink></span>
                            </div>

                            <p><b> Email : </b><a href="mailto:info@looksyummyapp.com"> info@looksyummyapp.com</a> </p>
                            <p><b>Phone : </b><a href="tel:954-870-1178"> 954-870-1178</a> / <a href="tel:954-870-1187">954-870-1187</a></p>
                        </div>
                    </div>
                </div>

                <div className="line_bottom"></div>

                <div className="footer_bottom">
                    <div className="theme_change">
                        <img src={theme_btn} alt="" />
                    </div>
                    <p>
                        copyrights@2022 lyt created by LYT
                    </p>

                </div>
            </div>
        </div>

    )
}

export default Footer;