import "./index.css"
import img1 from "../../../assets/images/icon1.png"
import img2 from "../../../assets/images/icon2.png"
import img3 from "../../../assets/images/icon3.png"
import img4 from "../../../assets/images/icon4.png"


function Community() {
    return (
        <div className="theme_container" id="community">
            <div className="community_wrapper">
                <div className="community_heading">
                    <h1>Our Community</h1>
                </div>
                <div className="community_container">
                    <div className="sub_community">
                        <div className="community_icon_img">
                            <img src={img1} alt="" />
                        </div>
                        <div className="ommunity_sub_text">
                        <a className="a_link" href="https://twitter.com/LooksYummyApp?t=lxk482mP-klWlCbfSGo6DA&s=09"><h3>   Twitter</h3></a>
                        
                            <p>+1000 Followers</p>
                        </div>
                    </div>
                    <div className="sub_community sub_community_m">
                        <div className="community_icon_img" >
                            <img src={img2} alt="" />
                        </div>
                        <div className="ommunity_sub_text">
                        <a className="a_link" href="http://t.me/looksyummytokenofficial"><h3>   Telegram</h3></a>
                            <p>+1000 Members</p>
                        </div>
                    </div>
                    <div className="sub_community sub_community_m">
                        <div className="community_icon_img">
                            <img src={img3} alt="" />
                        </div>
                        <div className="ommunity_sub_text">
                        <a className="a_link" href="https://discord.gg/dpBcPC7T"><h3>   Discord</h3></a>
                            <p>+1000 Members</p>
                        </div>
                    </div>
                    <div className="sub_community">
                        <div className="community_icon_img">
                            <img src={img4} alt="" />
                        </div>
                        <div className="ommunity_sub_text">
                        <a className="a_link" href="https://medium.com/@looksyummyapp"><h3>   Medium</h3></a>
                            <p>+1000 Members</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Community;