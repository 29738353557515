import "./index.css"
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useState, useContext, useEffect } from "react";
import logo from "../../../assets/images/f_logo.png"
import img1 from "../../../assets/images/signin.png"
import img2 from "../../../assets/images/wallet.png"
import img3 from "../../../assets/images/nav_theme_btn.png"
import { NavLink } from "react-router-dom";



export default function SubNavbar({ heading, currency, menu_item }) {
    const [open, setOpen] = useState(true)
    return (
        <div className="top_container">
            <div className="first_heading_row">
                <div className="sub_left_heading">
                    <h3>{heading}</h3>
                </div>

                <div className="sub_right_balance">
                    <h3> {currency.name}: <span> {currency.value}</span></h3>
                </div>
            </div>
            <div className="second_menu_row">

                {
                    menu_item?.map((val, i) => {
                        if (val.link == 'none') {
                            return (<span id={i}> {val.name}</span>)
                        }
                        else {
                            return (<NavLink to={val.link} className={({ isActive }) => (isActive ? 'active_menu_sub' : 'in_active_menu')} id={i}><span> {val.name}</span></NavLink>)
                        }

                    })
                }

            </div>
            <div className="third_connect_wallet">
                <div className="w_connect_content">
                    <p>Connect wallet to start using yummy farm</p>
                </div>
                <div className="swap_button"><NavLink to={'/vault-calculator'}> <button>Connect Wallet</button></NavLink></div>
            </div>
            <div className="last_bottom_text">
                <p>Dont have a wallet ? <a href=""> watched up guide to get started</a> </p>
            </div>


        </div >
    )
}